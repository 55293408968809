import { FC } from 'react';
import IconProps from './IconProps';

export enum ChevronType {
  UP,
  RIGHT,
  DOWN,
  LEFT,
}

export interface ChevronIconProps extends IconProps {
  type: ChevronType;
}

export const ChevronIcon: FC<ChevronIconProps> = (props) => {
  const { type, onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  switch (type) {
    case ChevronType.UP:
      return (
        <svg
          data-cy="chevron-up"
          onClick={onClick}
          className={`inline ${cursor} ${className} flex-shrink-0`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clipRule="evenodd"
            data-testid="icon-chevron-up"
          />
        </svg>
      );
    case ChevronType.RIGHT:
      return (
        <svg
          data-cy="chevron-right"
          onClick={props.onClick}
          className={`inline ${cursor} ${className} flex-shrink-0`}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          data-testid="icon-chevron-right"
        >
          <path d="M11 16L15 12L11 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    case ChevronType.DOWN:
      return (
        <svg
          data-cy="chevron-down"
          onClick={onClick}
          className={`inline ${cursor} ${className} flex-shrink-0`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
            data-testid="icon-chevron-down"
          />
        </svg>
      );
    case ChevronType.LEFT:
    default:
      return (
        <svg
          data-cy="chevron-left"
          onClick={onClick}
          className={`inline ${cursor} ${className} flex-shrink-0`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          data-testid="icon-chevron-left"
        >
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      );
  }
};
